import styled, { css } from "styled-components";
import { AlignmentHorizontalOption } from "@ecp-redux/dto/themeSettings/themeSettings.types";
import { isNumber } from "lodash";

export const StyledArticleImageLinkWrapper = styled.div<{
  alignment: AlignmentHorizontalOption;
  buttonWidth?: number | string;
}>(
  ({ alignment, buttonWidth }) => css`
    .article-list__link {
      overflow-wrap: normal;
      word-break: normal;
      text-align: ${alignment.toLowerCase()};
    }

    .article-list__button {
      overflow-wrap: normal;
      word-break: normal;
      text-align: ${alignment.toLowerCase()};
      width: ${isNumber(buttonWidth) ? `${buttonWidth}%` : buttonWidth};
    }
  `
);
