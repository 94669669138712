import React from "react";
import styled, { css } from "styled-components";
import {
  HoverEffects,
  ProductHoverEffects,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import {
  AlignmentHorizontalOption,
  AlignmentVerticalOption,
  TSizeCSS,
} from "@ecp-redux/dto/themeSettings/themeSettings.types";
import {
  getJustifyContentValue,
  isClientSide,
} from "../../../../../helpers/helpers";
import composeCss, {
  alignmentOptionsToValue,
} from "../../../../../settingsPatterns/composeCss";
import { convertColorIdToHex } from "../../../../../settingsPatterns/settingsPatterns.methods";
import { StyledClipboardIconContainer } from "../../../../../shared/components/Clipboard/Clipboard.styled";
import StyledText from "../../../../styleElements/StyledText/StyledText";
import {
  IProductAttribute,
  IProductModuleRatingStyle,
  IProductModuleSettings,
  IProductName,
} from "./ProductModule.types";

export const StyledProductModuleWrapper = styled.div<{
  module: IProductModuleSettings;
  $imageHover: HoverEffects | ProductHoverEffects;
  $spaceBottom: number;
  $productUrl: string | null;
  imageHeight: number;
  leftPictureMode?: {
    photoSize: number;
    pictureAlignment: AlignmentVerticalOption;
    attributesAlignment: AlignmentVerticalOption;
    spaceBetweenPhotoAndAttributes: number;
    badgesShown: boolean;
  };
  productWithoutCoverPhoto: boolean;
  productPhotoHeight: number;
}>(
  ({
    module: { padding, background, border },
    $imageHover,
    $spaceBottom,
    $productUrl,
    leftPictureMode,
    imageHeight,
    productWithoutCoverPhoto,
    productPhotoHeight,
    theme,
  }) => {
    let heightStyle = "";

    if (!isClientSide()) {
      heightStyle = `height: ${imageHeight}px;`;
    }

    return css`
      position: relative;
      padding: ${composeCss.padding(padding)};
      background: ${convertColorIdToHex(background, theme.colorPalette)};
      border: ${composeCss.border(border, theme)};
      height: 100%;
      width: 100%;
      overflow: hidden;
      word-break: break-all;

      &:hover {
        .product-module__container__variants-container {
          opacity: 1;
        }
      }

      .currentUrl,
      a:not(.product-module__container__variants-container a) {
        pointer-events: ${$productUrl ? "inherit" : "none"};
        overflow: hidden;
      }

      .imageContainer {
        width: 100%;
        cursor: ${$productUrl ? "pointer" : "default"};
        margin-bottom: ${$spaceBottom}px;
        ${$imageHover !== "SECOND" && composeCss.hoverEffect($imageHover)}

        ${leftPictureMode
          ? `
          height: 100%;

          img {
            height: inherit !important;
            ${
              leftPictureMode.pictureAlignment
                ? `object-position: ${leftPictureMode.pictureAlignment} !important;`
                : ""
            };
          }`
          : heightStyle}
      }

      .product-module__link__container {
        position: relative;

        ${leftPictureMode
          ? `height: 100%;
            width: 100%;
            display: flex;`
          : ""}

        ${!leftPictureMode && productWithoutCoverPhoto
          ? `height: ${productPhotoHeight ?? 0}px;`
          : ""}

        &__badge-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;

          ${leftPictureMode
            ? `
              display: flex;
              justify-content: ${getJustifyContentValue(
                leftPictureMode.badgesShown
              )};
              width: 100%;

              ${StyledClipboardIconContainer} {
                position: relative;
              }

              &__badge {
                overflow: hidden;
                position: relative;
                word-break: normal;
              }`
            : ""}
        }

        &__variants-container {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          transition: opacity 0.2s linear;
        }
      }

      .hoverPhoto {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        &:hover {
          opacity: 1;
        }
        background-color: white;
      }
    `;
  }
);

export const StyledLeftPictureMode = styled.div<{
  gap?: number;
  attributesAlignment?: AlignmentVerticalOption;
  photoSize?: number;
}>(
  ({ gap, attributesAlignment, photoSize }) => css`
    display: flex;
    height: 100%;
    ${gap ? `gap: ${gap}px` : ""};
    ${attributesAlignment
      ? `align-items: ${alignmentOptionsToValue(attributesAlignment)}`
      : ""};

    .product-module {
      &__link {
        display: flex;
        height: 100%;
        ${photoSize ? `width: ${photoSize}%` : ""};
      }

      &__left-picture-mode-attributes-container {
        display: flex;
        flex-direction: column;
        ${photoSize ? `width: ${100 - photoSize}%` : ""};
      }

      &__left-picture-mode-variants-container {
        width: 100%;
        display: flex;
      }
    }
  `
);

export const StyledContentProductWrapper = styled.div<
  Pick<IProductModuleSettings, "spaceBetweenTextElements" | "alignment"> & {
    button: { custom: boolean; customWidth: number };
    customButton: { custom: boolean; customWidth: number };
    secondaryButton: { custom: boolean; customWidth: number };
  }
>(
  ({
    spaceBetweenTextElements,
    alignment,
    button,
    customButton,
    secondaryButton,
  }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spaceBetweenTextElements}px;
    align-items: ${alignmentOptionsToValue(alignment)};
    width: 100%;
    > div {
      text-align: ${alignment};
    }
    > a {
      text-align: ${alignment};
    }

    .product-module__content-container {
      &__button {
        width: ${button.custom ? `${button.customWidth}%` : "auto"};
      }

      &__custom-button {
        width: ${customButton.custom ? `${customButton.customWidth}%` : "auto"};
      }
    }
    .button-container {
      a {
        width: 100%;
      }
      width: ${button.custom ? `${button.customWidth}%` : "auto"};
    }
    .secondary-button-container {
      a {
        width: 100%;
      }
      width: ${secondaryButton.custom
        ? `${secondaryButton.customWidth}%`
        : "auto"};
    }
    button {
      width: 100%;
    }
    .actionButtons,
    .add-to-cart__container {
      justify-content: ${alignmentOptionsToValue(alignment)};
    }
  `
);

export const StyledProductName = styled(StyledText)`
  background: blue;
  border: 1px solid black;
  a {
    background: red;
  }
`;

export const StyledPrefixSuffix = styled.div<{
  productSettings:
    | IProductName
    | IProductAttribute
    | IProductModuleRatingStyle
    | undefined;
}>`
  display: flex;
  align-items: center;

  ${(props) => {
    const { productSettings } = props;
    if (!productSettings) return "";

    const { prefix, suffix } = productSettings;

    return `
      ${
        prefix.show && prefix.heading
          ? `
        .prefix-suffix:first-child {
          ${`margin: 0 ${
            productSettings.prefix.spaceBetweenHeadingAndAttribute ?? 0
          }px 0 0;`}
        };
      `
          : ""
      }

      ${
        suffix.show && suffix.heading
          ? `
        .prefix-suffix:last-child {
          ${`margin: 0 0 0 ${
            productSettings.suffix.spaceBetweenHeadingAndAttribute ?? 0
          }px`}
        };
      `
          : ""
      }
    `;
  }}
`;

export const StyledBlankCoverPhoto = styled.div<{ height: number }>`
  height: ${({ height }) => height}px;
  width: 100%;
  display: grid;
  place-content: center;
`;

export const StyledSpaceKeeper = styled.div<{
  height?: number;
  rows: number;
}>(({ height, rows }) => {
  // if it has to handle text, height props should be line-height from fontStyle
  return css`
    ${height ? ` min-height:${height * rows}px;` : "height: fit-content;"}
    display: -webkit-box;
    -webkit-line-clamp: ${rows};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    a {
      word-break: break-word;
    }

    .product-module__content-container__old-price__line-through {
      text-decoration-line: line-through;
    }
  `;
});

export const StyledAttributesInRow = styled.div<{
  spaceBetweenTextElements: number;
  button: { custom: boolean; customWidth: number };
  customButton: { custom: boolean; customWidth: number };
  secondaryButton: { custom: boolean; customWidth: number };
}>(({ spaceBetweenTextElements, button, customButton, secondaryButton }) => {
  return css`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: ${spaceBetweenTextElements}px;

    .product-module__content-container {
      ${button.custom
        ? css`
            &__button {
              width: ${button.customWidth}%;
            }
          `
        : null}

      ${customButton.custom
        ? css`
            &__custom-button {
              width: ${customButton.customWidth}%;
            }
          `
        : null}
    }
    button {
      width: 100%;
    }
    .button-container {
      a {
        width: 100%;
      }
      width: ${button.custom ? `${button.customWidth}%` : "auto"};
    }

    .secondary-button-container {
      a {
        width: 100%;
      }
      width: ${secondaryButton.custom
        ? `${secondaryButton.customWidth}%`
        : "auto"};
    }
  `;
});

export const StyledRow = styled.div<{
  rowIndex: number;
  alignment: AlignmentHorizontalOption;
  children: React.ReactNode;
}>(({ alignment, children }) => {
  return css`
    display: grid;
    width: 100%;

    ${`
    grid-template-columns: repeat(${React.Children.count(children)}, 1fr);
    grid-template-rows: 1fr;
    gap: 0px 0px;
    grid-auto-flow: row;
    justify-items: ${alignmentOptionsToValue(alignment)} ;`}

    .actionButtons, .add-to-cart__container {
      justify-content: ${alignmentOptionsToValue(alignment)};
    }
  `;
});

export const StyledLeftProductModuleWrapper = styled.div<{
  module: IProductModuleSettings;
  $imageHover: HoverEffects | ProductHoverEffects;
  $spaceLeft: number;
  $productUrl: string | null;
  productWithoutCoverPhoto: boolean;
  isTwoColumn: boolean;
  firstColumnWidth: TSizeCSS;
  secondColumnWidth: TSizeCSS;
  gapBetweenColumns: number;
  productPhotoHeight?: number;
}>(
  ({
    module: { padding, background, border },
    $imageHover,
    $spaceLeft,
    $productUrl,
    productPhotoHeight,
    firstColumnWidth,
    secondColumnWidth,
    isTwoColumn,
    gapBetweenColumns,
    theme,
  }) => {
    const firstColumnToNumber = parseInt(firstColumnWidth);
    const secondColumnToNumber = parseInt(secondColumnWidth);
    return css`
      position: relative;
      display: flex;
      padding: ${composeCss.padding(padding)};
      background: ${convertColorIdToHex(background, theme.colorPalette)};
      border: ${composeCss.border(border, theme)};
      height: 100%;
      width: 100%;
      overflow: hidden;
      word-break: break-all;

      &:hover {
        .product-module__container__variants-container {
          opacity: 1;
        }
      }

      .currentUrl,
      a:not(.product-module__container__variants-container a) {
        pointer-events: ${$productUrl ? "inherit" : "none"};
        overflow: hidden;
      }

      .imageContainer {
        width: 300px;
        cursor: ${$productUrl ? "pointer" : "default"};
        ${$imageHover !== "SECOND" && composeCss.hoverEffect($imageHover)}
      }

      .line-product-module__link {
        position: relative;
        width: 300px;
        height: ${productPhotoHeight ?? 0}px;

        &__badge-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
        }

        &__variants-container {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          transition: opacity 0.2s linear;
        }
      }

      .hoverPhoto {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        &:hover {
          opacity: 1;
        }
        background-color: white;
      }
      .line-product-module__attributes-container {
        display: grid;
        width: 100%;
        gap: ${gapBetweenColumns}px;
        margin-left: ${$spaceLeft}px;
        grid-template-columns: ${isTwoColumn
          ? `${firstColumnToNumber}fr ${secondColumnToNumber}fr;`
          : `1fr;`};
      }
    `;
  }
);
