import styled from "styled-components";
import {
  IBoxBaseSettings,
  ICustomBackgroundPlaceholder,
  IElementArticleImage,
} from "@ecp-redux/dto/themeSettings/settingsPatterns.types";
import ConditionalHTMLElement from "../../../../global/components/ConditionalHTMLElement/ConditionalHTMLElement";
import composeCss from "../../../composeCss";

const StyledArticleImage = styled(ConditionalHTMLElement).attrs((props) => ({
  renderAs: props?.renderAs ?? "img",
  show: props?.["$settings"].show,
}))<IBoxBaseSettings<IElementArticleImage & ICustomBackgroundPlaceholder>>(
  ({ $settings }) => `
    display: block;
    width: ${$settings.width}px;
    height: ${$settings.height}px;
    ${composeCss.hoverEffect($settings.hover)}
    object-fit: contain;
`
);

export default StyledArticleImage;
