export enum FilterOperators {
  IN = "IN",
  LIKE = "LIKE",
  NULL = "NULL",
  EQ = "EQ",
  GTE = "GTE",
  LTE = "LTE",
  NOT_EQ = "NOT_EQ",
  NOT_GTE = "NOT_GTE",
  NOT_LTE = "NOT_LTE",
  NOT_IN = "NOT_IN",
  NOT_LIKE = "NOT_LIKE",
  NOT_NULL = "NOT_NULL",
  PARAM = "PARAM",
}
